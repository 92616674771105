import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

// Props
import { EditOutlined, LockFilled } from '@ant-design/icons';
import { AssignConfirmProps } from './useEmployeeAccessProfilesReduxState';

// Models
import {
  AccessProfile,
  AccessProfileAuthorizedUser,
  AccessProfileVerificationStatus,
} from '../../../models/AccessProfile';
import { Employee } from '../../../models/Employee';

// Components
import { Translated } from '../../../components/UI/Core';
import { MultiSelect, MultiSelectItem } from '../../../components/UI/MultiSelect/MultiSelect';
import UseRequestedAccessProfileDrawer from '../../Drawer/useRequestedAccessProfileDrawer';
import { PersonType } from '../../../models/enums/PersonType';
import { assignAccessProfiles } from '../../../store/Employees/EmployeeAccessProfiles/EmployeeAccessProfiles.redux';
import { useAppDispatch } from '../../App/useRedux';
import { AccessProfileFormValues } from '../../../models/AccessProfileFormValues';

// Props
interface EmployeeAccessProfilesFormProps {
  employee: Employee | null;
  accessProfiles: Array<AccessProfile> | null;
  showAssignConfirm: (props: AssignConfirmProps) => void;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const useEmployeeAccessProfilesForm = ({
  employee,
  accessProfiles,
  showAssignConfirm,
}: EmployeeAccessProfilesFormProps) => {
  // State
  const [accessProfileIds, setAccessProfileIds] = useState<Array<string>>([]);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [matchingAccessProfiles, setMatchingAccessProfiles] = useState<Array<AccessProfileAuthorizedUser>>([]);
  const dispatch = useAppDispatch();

  // Get the Keys (Id) from all Companies of a Location
  useEffect(() => {
    setAccessProfileIds(
      accessProfiles
        ?.filter((accessProfile) =>
          employee?.AccessProfiles?.some(
            (employeeAccessProfile) => employeeAccessProfile.AccessProfile.Id === accessProfile.Id
          )
        )
        ?.map((x) => x.Id) ?? []
    );
  }, [accessProfiles, employee]);

  useEffect(() => {
    const updatedProfiles =
      accessProfiles
        ?.filter((accessProfile) => accessProfileIds.includes(accessProfile.Id))
        .map<AccessProfileAuthorizedUser>((profile) => {
          const existingProfile = employee?.AccessProfiles.find((authUser) => authUser.AccessProfile.Id === profile.Id);

          return {
            Id: profile.Id,
            AccessProfile: profile,
            DenyReason: existingProfile?.DenyReason || '',
            RequestReason: existingProfile?.RequestReason || '',
            RequesterId: existingProfile?.RequesterId || '',
            ValidFrom: existingProfile?.ValidFrom || profile.ValidFrom,
            ValidTo: existingProfile?.ValidTo || profile.ValidTo,
            VerificationStatus: existingProfile?.VerificationStatus || 0,
            VerifierId: existingProfile?.VerifierId || '',
          };
        }) ?? [];

    setMatchingAccessProfiles(updatedProfiles);
  }, [accessProfileIds, accessProfiles, employee]);

  const openDrawer = (id: any, name: any) => {
    setSelectedId(id);
    setSelectedName(name);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setSelectedId('');
    setSelectedName('');
  };

  const getStatus = useCallback(
    (accessProfileId: string) => {
      return matchingAccessProfiles.find((authUser) => authUser.AccessProfile.Id === accessProfileId)
        ?.VerificationStatus;
    },
    [matchingAccessProfiles]
  );

  const getStatusStyles = (status: AccessProfileVerificationStatus) => {
    switch (status) {
      case AccessProfileVerificationStatus.Pending:
        return { buttonText: <Translated id="access.profile.verification.status.pending" />, buttonColor: 'Orange' };
      case AccessProfileVerificationStatus.Granted:
        return { buttonText: <Translated id="access.profile.verification.status.granted" />, buttonColor: '#52c41a' };
      case AccessProfileVerificationStatus.Refused:
        return { buttonText: <Translated id="access.profile.verification.status.refused" />, buttonColor: 'Red' };
      default:
        return { buttonText: '', buttonColor: '#fffff' };
    }
  };

  const existingProfile = employee?.AccessProfiles.find((authUser) => authUser.AccessProfile.Id === selectedId);
  const handleSaveEmployee = useCallback(
    async (formValue: any, validFrom: string, validTo: string) => {
      if (!employee) return;
      const updatedProfiles = employee?.AccessProfiles.map((authUser) => {
        if (authUser.AccessProfile.Id === selectedId) {
          return {
            ...authUser,
            DenyReason: authUser.DenyReason,
            RequestReason: formValue.Reason,
            RequesterId: authUser.RequesterId,
            ValidFrom: validFrom,
            ValidTo: validTo,
            VerificationStatus: authUser.VerificationStatus,
            VerifierId: authUser.VerifierId,
          };
        }
        return authUser;
      });
      console.log(employee?.AccessProfiles);

      const formValues: AccessProfileFormValues = {
        AccessProfiles: updatedProfiles,
        PersonId: employee?.Id,
        PersonType: PersonType.Employee,
      };

      dispatch(assignAccessProfiles({ formValues, employee }));
    },
    [employee, dispatch, selectedId]
  );

  // Components
  const EmployeeAccessProfilesManagementForm = useCallback(() => {
    // Get all Companies as MultiSelectItems
    const dataSource =
      accessProfiles?.map<MultiSelectItem>((accessProfile) => ({
        key: accessProfile.Id,
        title: accessProfile.Name,
        description: '',
        Renderer: (isAvailable: boolean) => {
          const status = getStatus(accessProfile.Id);
          const { buttonText, buttonColor } = getStatusStyles(status ?? 0);
          return (
            <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <span style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {accessProfile.Name}
              </span>
              {isAvailable
                ? accessProfile.RequiresVerification && <LockFilled style={{ marginRight: 8, color: '#0c195e' }} />
                : accessProfile.RequiresVerification && (
                    <Button
                      type="text"
                      size="small"
                      style={{
                        backgroundColor: buttonColor,
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '0',
                        width: '80px',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (status === 0) {
                          openDrawer(accessProfile.Id, accessProfile.Name);
                        }
                      }}
                    >
                      {buttonText}
                      {status === 0 && <EditOutlined />}
                    </Button>
                  )}
            </span>
          );
        },
      })) ?? [];

    return (
      <>
        <MultiSelect dataSource={dataSource} targetKeys={accessProfileIds} setTargetKeys={setAccessProfileIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            employee &&
            showAssignConfirm({
              formValues: {
                AccessProfiles: matchingAccessProfiles,
                PersonId: employee?.Id,
                PersonType: PersonType.Employee,
              },
              employee,
            })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
        <UseRequestedAccessProfileDrawer
          visible={drawerVisible}
          id={selectedId}
          name={selectedName}
          onClose={closeDrawer}
          onSave={handleSaveEmployee}
          RequestReason={existingProfile?.RequestReason ?? ''}
        />
      </>
    );
  }, [
    accessProfiles,
    accessProfileIds,
    drawerVisible,
    selectedId,
    selectedName,
    handleSaveEmployee,
    existingProfile?.RequestReason,
    getStatus,
    employee,
    showAssignConfirm,
    matchingAccessProfiles,
  ]);

  return useMemo(
    () => ({
      AccessProfilesManagementForm: EmployeeAccessProfilesManagementForm,
    }),
    [EmployeeAccessProfilesManagementForm]
  );
};
