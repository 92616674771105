import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

// Props
import { EditOutlined, LockFilled } from '@ant-design/icons';
import { AssignConfirmProps } from './useVisitorAccessProfilesReduxState';

// Models
import {
  AccessProfile,
  AccessProfileAuthorizedUser,
  AccessProfileVerificationStatus,
} from '../../../models/AccessProfile';
import { Visitor } from '../../../models/Visits/Visitor';

// Components
import { Translated } from '../../../components/UI/Core';
import { MultiSelect, MultiSelectItem } from '../../../components/UI/MultiSelect/MultiSelect';
import UseRequestedAccessProfileDrawer from '../../Drawer/useRequestedAccessProfileDrawer';
import { PersonType } from '../../../models/enums/PersonType';
import { AccessProfileFormValues } from '../../../models/AccessProfileFormValues';
import { useAppDispatch } from '../../App/useRedux';
import { assignAccessProfiles } from '../../../store/Visitors/VisitorAccessProfiles/VisitorAccessProfiles.redux';

// Props
interface VisitorAccessProfilesFormProps {
  visitor: Visitor | null;
  accessProfiles: Array<AccessProfile> | null;
  showAssignConfirm: (props: AssignConfirmProps) => void;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const useVisitorAccessProfilesForm = ({
  visitor,
  accessProfiles,
  showAssignConfirm,
}: VisitorAccessProfilesFormProps) => {
  // State
  const [accessProfileIds, setAccessProfileIds] = useState<Array<string>>([]);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [matchingAccessProfiles, setMatchingAccessProfiles] = useState<Array<AccessProfileAuthorizedUser>>([]);
  const dispatch = useAppDispatch();

  // Get the Keys (Id) from all Companies of a Location
  useEffect(() => {
    setAccessProfileIds(
      accessProfiles
        ?.filter((accessProfile) =>
          visitor?.AccessProfiles?.some(
            (visitorAccessProfile) => visitorAccessProfile.AccessProfile.Id === accessProfile.Id
          )
        )
        ?.map((x) => x.Id) ?? []
    );
  }, [accessProfiles, visitor]);

  useEffect(() => {
    const updatedProfiles =
      accessProfiles
        ?.filter((accessProfile) => accessProfileIds.includes(accessProfile.Id))
        .map<AccessProfileAuthorizedUser>((profile) => {
          const existingProfile = visitor?.AccessProfiles.find((authUser) => authUser.AccessProfile.Id === profile.Id);

          return {
            Id: profile.Id,
            AccessProfile: profile,
            DenyReason: existingProfile?.DenyReason || '',
            RequestReason: existingProfile?.RequestReason || '',
            RequesterId: existingProfile?.RequesterId || '',
            ValidFrom: existingProfile?.ValidFrom || profile.ValidFrom,
            ValidTo: existingProfile?.ValidTo || profile.ValidTo,
            VerificationStatus: existingProfile?.VerificationStatus || 0,
            VerifierId: existingProfile?.VerifierId || '',
          };
        }) ?? [];

    setMatchingAccessProfiles(updatedProfiles);
  }, [accessProfileIds, accessProfiles, visitor]);
  const openDrawer = (id: any, name: any) => {
    setSelectedId(id);
    setSelectedName(name);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setSelectedId('');
    setSelectedName('');
  };

  const existingProfile = visitor?.AccessProfiles.find((authUser) => authUser.AccessProfile.Id === selectedId);
  const handleSaveVisitor = useCallback(
    async (formValue: any, validFrom: string, validTo: string) => {
      if (!visitor) return;

      const updatedProfiles = visitor?.AccessProfiles.map((authUser) => {
        if (authUser.AccessProfile.Id === selectedId) {
          return {
            ...authUser,
            DenyReason: authUser.DenyReason,
            RequestReason: formValue.Reason,
            RequesterId: authUser.RequesterId,
            ValidFrom: validFrom,
            ValidTo: validTo,
            VerificationStatus: authUser.VerificationStatus,
            VerifierId: authUser.VerifierId,
          };
        }
        return authUser;
      });

      const formValues: AccessProfileFormValues = {
        AccessProfiles: updatedProfiles,
        PersonId: visitor?.Id,
        PersonType: PersonType.Employee,
      };

      dispatch(assignAccessProfiles({ formValues, visitor }));
    },
    [visitor, dispatch, selectedId]
  );

  const getStatus = useCallback(
    (accessProfileId: string) => {
      return matchingAccessProfiles.find((authUser) => authUser.AccessProfile.Id === accessProfileId)
        ?.VerificationStatus;
    },
    [matchingAccessProfiles]
  );

  const getStatusStyles = (status: AccessProfileVerificationStatus) => {
    switch (status) {
      case AccessProfileVerificationStatus.Pending:
        return { buttonText: <Translated id="access.profile.verification.status.pending" />, buttonColor: 'Orange' };
      case AccessProfileVerificationStatus.Granted:
        return { buttonText: <Translated id="access.profile.verification.status.granted" />, buttonColor: '#52c41a' };
      case AccessProfileVerificationStatus.Refused:
        return { buttonText: <Translated id="access.profile.verification.status.refused" />, buttonColor: 'Red' };
      default:
        return { buttonText: '', buttonColor: '#fffff' };
    }
  };

  // Components
  const VisitorAccessProfilesManagementForm = useCallback(() => {
    const dataSource =
      accessProfiles?.map<MultiSelectItem>((accessProfile) => ({
        key: accessProfile.Id,
        title: accessProfile.Name,
        description: '',
        Renderer: (isAvailable: boolean) => {
          const status = getStatus(accessProfile.Id);
          const { buttonText, buttonColor } = getStatusStyles(status ?? 0);
          return (
            <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <span style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {accessProfile.Name}
              </span>
              {isAvailable
                ? accessProfile.RequiresVerification && <LockFilled style={{ marginRight: 8, color: '#0c195e' }} />
                : accessProfile.RequiresVerification && (
                    <Button
                      type="text"
                      size="small"
                      style={{
                        backgroundColor: buttonColor,
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '0',
                        width: '80px',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (status === 0) {
                          openDrawer(accessProfile.Id, accessProfile.Name);
                        }
                      }}
                    >
                      {buttonText}
                      {status === 0 && <EditOutlined />}
                    </Button>
                  )}
            </span>
          );
        },
      })) ?? [];

    return (
      <>
        <MultiSelect dataSource={dataSource} targetKeys={accessProfileIds} setTargetKeys={setAccessProfileIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            visitor &&
            showAssignConfirm({
              formValues: {
                AccessProfiles: matchingAccessProfiles,
                PersonId: visitor?.Id,
                PersonType: PersonType.Visitor,
              },
              visitor,
            })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
        <UseRequestedAccessProfileDrawer
          visible={drawerVisible}
          id={selectedId}
          name={selectedName}
          onClose={closeDrawer}
          onSave={handleSaveVisitor}
          RequestReason={existingProfile?.RequestReason ?? ''}
        />
      </>
    );
  }, [
    accessProfiles,
    accessProfileIds,
    drawerVisible,
    selectedId,
    selectedName,
    handleSaveVisitor,
    existingProfile?.RequestReason,
    visitor,
    getStatus,
    showAssignConfirm,
    matchingAccessProfiles,
  ]);

  return useMemo(
    () => ({
      AccessProfilesManagementForm: VisitorAccessProfilesManagementForm,
    }),
    [VisitorAccessProfilesManagementForm]
  );
};
