// CustomDrawer.tsx
import React, { useState } from 'react';
import { Button, Col, Drawer, Form, Input, Row, Select } from 'antd';
import styled from 'styled-components';

import { Option } from 'antd/lib/mentions';
import moment from 'moment';
import { Translated } from '../../components/UI/Core';
// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

interface CustomDrawerProps {
  visible: boolean;
  onClose: () => void;
  id: string;
  name: string;
  RequestReason: string;
  onSave: (formValue: any, validFrom: string, validTo: string) => Promise<void>;
}

const durationOptions = [
  { label: '1 Day', value: 'day' },
  { label: '1 Week', value: 'week' },
  { label: '1 Month', value: 'month' },
  { label: '3 Months', value: '3_months' },
  { label: '6 Months', value: '6_months' },
  { label: '1 Year', value: '1_year' },
  { label: '18 Months', value: '18_months' },
  { label: '2 Years', value: '2_years' },
];

const useRequestedAccessProfileDrawer: React.FC<CustomDrawerProps> = ({
  visible,
  onClose,
  id,
  name,
  onSave,
  RequestReason,
}) => {
  const [form] = Form.useForm();
  const [validFrom, setStartDate] = useState(moment().format());
  const [validTo, setEndDate] = useState(moment().add(1, 'year').format());

  const handleSave = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const formValue = await form.validateFields();
    await onSave(formValue, validFrom, validTo);
    onClose();
  };

  const updateEndDate = (duration: any) => {
    let newEndDate;

    switch (duration) {
      case 'day':
        newEndDate = moment();
        break;
      case 'week':
        newEndDate = moment().add(7, 'days');
        break;
      case 'month':
        newEndDate = moment().add(1, 'month');
        break;
      case '3_months':
        newEndDate = moment().add(3, 'months');
        break;
      case '6_months':
        newEndDate = moment().add(6, 'months');
        break;
      case '1_year':
        newEndDate = moment().add(1, 'year');
        break;
      case '18_months':
        newEndDate = moment().add(18, 'months');
        break;
      case '2_years':
        newEndDate = moment().add(2, 'years');
        break;
      default:
        newEndDate = moment();
    }
    setStartDate(moment().format());
    setEndDate(newEndDate.format());
  };

  const stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  return (
    <Drawer
      title={
        <>
          <Translated id="restricted.access.profile.edit" /> {name}
        </>
      }
      placement="right"
      onClose={onClose}
      open={visible}
      headerStyle={{ padding: '0px' }}
      bodyStyle={{ padding: '16px' }}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" form="custom-form" onClick={handleSave}>
            Save
          </Button>
        </div>
      }
    >
      <StyledForm
        id="custom-form"
        layout="vertical"
        form={form}
        initialValues={{ Id: id, Duration: '1_year', Reason: RequestReason }}
      >
        <Form.Item name="Id" hidden>
          <Input />
        </Form.Item>
        <Row>
          <Col>
            <Form.Item name="Duration" label={<Translated id="restricted.access.profile.duration" />}>
              <Select placeholder="Select duration" onClick={stopPropagation} onChange={updateEndDate}>
                {durationOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="Reason" label={<Translated id="restricted.access.profile.Reason" />}>
              <Input placeholder="Enter reason" onClick={stopPropagation} />
            </Form.Item>
          </Col>
        </Row>
      </StyledForm>
    </Drawer>
  );
};

export default useRequestedAccessProfileDrawer;
