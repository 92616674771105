import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// Components
import { Badge } from 'antd';
import { Translated } from '../../UI/Core/Translated';
import { Flex } from '../../UI/Base';
import { useData } from '../../../hooks/App/useData';
import { ApiEndpoints } from '../../../data/ApiEndpoints';
import { PersonType } from '../../../models/enums/PersonType';

// Props
interface AppMenuItemProps {
  id: string;
  to: string;
  icon?: string;
  IconComponent?: any;
}

export const VerificationAppMenuItem: React.FC<AppMenuItemProps> = ({
  id,
  to,
  icon = undefined,
  IconComponent = undefined,
}) => {
  const { data: authorizedUserAccessProfilesCount, fetch: fetchAuthorizedUserAccessProfilesCount } = useData(
    ApiEndpoints.accessProfileVerification.authorizedUserAccessProfilesCount,
    null
  );

  useEffect(() => {
    fetchAuthorizedUserAccessProfilesCount({ personType: PersonType.Default });
  }, [fetchAuthorizedUserAccessProfilesCount]);

  return (
    <Link to={to}>
      <Flex alignItems="center">
        {IconComponent ? <IconComponent /> : icon && <i className={`icon icon-${icon}`} />}
        <Translated id={`appMenu.${id}`} />
        <div>
          {authorizedUserAccessProfilesCount !== null && (
            <Badge count={authorizedUserAccessProfilesCount} style={{ backgroundColor: '#d63700', marginLeft: 10 }} />
          )}
        </div>
      </Flex>
    </Link>
  );
};
